import React ,{lazy} from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { history } from './_helpers/history';

const Homepage = lazy(() => import('./Homepage/Homepage'));
function App() {
    return (
      <div className="App">
        <Router history={history}>
          <Routes>
            <Route exact path="/" element={<Homepage/>} />
            <Route path='*' element={<Homepage/>} />
          </Routes>
        </Router>
      </div>
      
    )

}

export default App;
